import React from "react";
import PHBanner from "../../components/PHBanner";

const AppRoute = ({ component: Component, layout: Layout, ...rest }) => {
  return (
    <Layout>
      <Component />
    </Layout>
  );
};

export default AppRoute;
