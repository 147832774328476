import React from "react";
import Home from "../../aboveTheFold/views/Home";
import LayoutDefault from "../../aboveTheFold/layouts/LayoutDefault";
import AppRoute from "../../aboveTheFold/utils/AppRoute";
// import "../../aboveTheFold/assets/scss/style.scss";

export default () => {
  require("../../aboveTheFold/assets/scss/style.scss");
  return <AppRoute component={Home} layout={LayoutDefault} />;
};
