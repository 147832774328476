import React, { useState } from "react";
import classNames from "classnames";
import { SectionSplitProps } from "../../utils/SectionProps";
import SectionHeader from "./partials/SectionHeader";
import Image from "../elements/Image";
import DownloadButton from "../custom/DownloadButton";
import DownloadFigma from "../custom/DownloadFigma";
import FormModal from "./partials/FormModal";
import redirect from "../../../utils/redirect";

const propTypes = {
  ...SectionSplitProps.types,
};

const defaultProps = {
  ...SectionSplitProps.defaults,
};

const FeaturesSplit = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  invertMobile,
  invertDesktop,
  alignTop,
  imageFill,
  ...props
}) => {
  const [formModalActive, setFormmodalactive] = useState(false);

  const openFormModal = (e) => {
    e.preventDefault();
    setFormmodalactive(true);
  };

  const closeModal = (e) => {
    e.preventDefault();
    setFormmodalactive(false);
  };

  const outerClasses = classNames(
    "features-split section",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "features-split-inner section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  const splitClasses = classNames(
    "split-wrap",
    invertMobile && "invert-mobile",
    invertDesktop && "invert-desktop",
    alignTop && "align-top"
  );

  const sectionHeader = {
    title: "Embrace your process",
    paragraph:
      "Above the Fold plugin marks the above the fold section for all of your selected Artboards. In that way, you can be safe that your Call to Action will be visible when the page loads.",
  };

  return (
    <section {...props} className={outerClasses} id="steps">
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className={splitClasses}>
            <div className="split-item">
              <div
                className="split-item-content center-content-mobile reveal-from-left"
                data-reveal-container=".split-item"
              >
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Step 1
                </div>
                <h3 className="mt-0 mb-12">Select your Artboards</h3>
                <p className="m-0">
                  Above the Fold plugin analyze one or multiple Artboards at
                  once. You just have to choose the Artboards you wish to test!
                </p>
              </div>
              <div
                className={classNames(
                  "split-item-image center-content-mobile reveal-from-bottom",
                  imageFill && "split-item-image-fill"
                )}
                data-reveal-container=".split-item"
              >
                <img
                  src={
                    "https://d34cuim5v38p5e.cloudfront.net/visualeyes/website/atf/step1.jpg"
                  }
                  alt="Features split 01"
                  width={528}
                  height={396}
                />
              </div>
            </div>

            <div className="split-item">
              <div
                className="split-item-content center-content-mobile reveal-from-right"
                data-reveal-container=".split-item"
              >
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Step 2
                </div>
                <h3 className="mt-0 mb-12">Choose the target device</h3>
                <p className="m-0">
                  Once you have selected your Artboard(s), the next step is to
                  choose the appropiate device target from the plugin's panel
                  menu.
                </p>
              </div>
              <div
                className={classNames(
                  "split-item-image center-content-mobile reveal-from-bottom",
                  imageFill && "split-item-image-fill"
                )}
                data-reveal-container=".split-item"
              >
                <img
                  src={
                    "https://d34cuim5v38p5e.cloudfront.net/visualeyes/website/atf/step2.jpg"
                  }
                  alt="Features split 02"
                  width={528}
                  height={396}
                />
              </div>
            </div>

            <div className="split-item">
              <div
                className="split-item-content center-content-mobile reveal-from-left"
                data-reveal-container=".split-item"
              >
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Step 3
                </div>
                <h3 className="mt-0 mb-12">Get back results</h3>
                <p className="m-0 mb-24">
                  The analysis runs instantly and the results are visible as an
                  extra layer on top of each Artboard. Now, you can review the
                  visibility of your elements!
                </p>
                <DownloadButton
                  style={{ margin: "auto" }}
                  onClick={openFormModal}
                />
                <DownloadFigma
                  style={{ margin: "auto" }}
                  onClick={() => redirect("https://bit.ly/3duoVGA")}
                  style={{ marginTop: "1rem" }}
                />
                <FormModal
                  formModalActive={formModalActive}
                  closeModal={closeModal}
                />
              </div>
              <div
                className={classNames(
                  "split-item-image center-content-mobile reveal-from-bottom",
                  imageFill && "split-item-image-fill"
                )}
                data-reveal-container=".split-item"
              >
                <img
                  src={
                    "https://d34cuim5v38p5e.cloudfront.net/visualeyes/website/atf/step3.jpg"
                  }
                  alt="Features split 03"
                  width={528}
                  height={396}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

FeaturesSplit.propTypes = propTypes;
FeaturesSplit.defaultProps = defaultProps;

export default FeaturesSplit;
