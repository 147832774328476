import React from "react";

const POST_ID = "201400";

export const PHBanner = ({ dark = true }) => {
  return (
    <a
      href="https://www.producthunt.com/posts/above-the-fold-plugin"
      target="_blank"
      rel="noreferrer"
    >
      <img
        src={`https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=${POST_ID}&theme=${
          dark ? "dark" : "light"
        }`}
        alt="Above the Fold | Sketch and Figma plugin to mark the above the fold section | Product Hunt Embed"
        style={{
          zIndex: "100000000",
          margin: "0",
          position: "fixed",
          bottom: "0",
          left: 0,
          margin: "1rem",
        }}
      />
    </a>
  );
};

export default PHBanner;
