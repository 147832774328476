import React from "react";
import classNames from "classnames";
import { SectionTilesProps } from "../../utils/SectionProps";
import SectionHeader from "./partials/SectionHeader";
import Image from "../elements/Image";
import AnimatedNumber from "animated-number-react";

const propTypes = {
  ...SectionTilesProps.types,
};

const defaultProps = {
  ...SectionTilesProps.defaults,
};
const Compare = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {
  const outerClasses = classNames(
    "features-tiles section",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "features-tiles-inner section-inner pt-0",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  const tilesClasses = classNames(
    "tiles-wrap center-content",
    pushLeft && "push-left"
  );

  const sectionHeader = {
    title: "Viewport vs Screen",
    paragraph:
      "For example, let's compare the size of your current viewport to your total screen dimensions:",
  };

  return (
    <section {...props} className={outerClasses} id="how">
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />

          <CompareWidget />
        </div>
      </div>
    </section>
  );
};

const CompareWidget = () => {
  const viewport = useWindowSize();

  if (typeof window === "undefined") return <></>;
  if (typeof window === "undefined" && !window) return <></>;
  if (typeof window === "undefined" && !window.screen) return <></>;
  if (typeof window === "undefined" && !window.screen.width) return <></>;
  if (typeof window === "undefined" && !window.screen.height) return <></>;

  const screen = {
    width: typeof window !== "undefined" ? window.screen.width : 0,
    height: typeof window !== "undefined" ? window.screen.height : 0,
  };

  const isViewportScreenSame =
    viewport.width === screen.width && viewport.height === screen.height;

  const formatValue = (value) => value.toFixed(0);
  const duration = 1000;

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {/* <p className="mt-16 text-sm">
        For example, let's check your current window:
      </p> */}
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "2rem",
          borderRadius: "32px",
          background: isViewportScreenSame
            ? "rgb(10, 123, 72)"
            : "rgb(254, 68, 78)",
        }}
      >
        <div>
          <small
            style={{
              margin: 0,
              fontSize: "14px",
              marginBottom: ".5rem",
              color: "#fff",
            }}
          >
            Viewport (px)
          </small>
          {/* <br /> */}
          <p
            style={{
              margin: 0,
              color: "#fff",
              fontSize: "20px",
              fontWeight: 500,
            }}
          >
            <AnimatedNumber
              value={viewport.width}
              duration={duration}
              formatValue={formatValue}
            />{" "}
            x{" "}
            <AnimatedNumber
              value={viewport.height}
              duration={duration}
              formatValue={formatValue}
            />
          </p>
        </div>
        <div
          style={{
            fontSize: "32px",
            color: "#fff",
            padding: "0 1rem",
          }}
        >
          {isViewportScreenSame ? "=" : "≠"}
        </div>
        <div>
          <small
            style={{
              margin: 0,
              fontSize: "14px",
              marginBottom: ".5rem",
              color: "#fff",
            }}
          >
            Screen size (px)
          </small>
          {/* <br /> */}
          <p
            style={{
              margin: 0,
              color: "#fff",
              fontSize: "20px",
              fontWeight: 500,
            }}
          >
            {screen.width} x {screen.height}{" "}
          </p>
        </div>
      </div>
      <p
        style={{
          marginBottom: 0,
          fontSize: "24px",
          fontWeight: "bold",
          color: isViewportScreenSame ? "rgb(10, 123, 72)" : "rgb(254, 68, 78)",
        }}
      >
        Danger Zone ={" "}
        <AnimatedNumber
          formatValue={formatValue}
          value={screen.height - viewport.height}
          duration={duration}
        />{" "}
        px
      </p>
    </div>
  );
};

function useWindowSize() {
  const isClient = typeof window === "object";

  function getSize() {
    return {
      width: isClient ? window.innerWidth : undefined,
      height: isClient ? window.innerHeight : undefined,
    };
  }

  const [windowSize, setWindowSize] = React.useState(getSize);

  React.useEffect(() => {
    if (!isClient) {
      return false;
    }

    function handleResize() {
      setWindowSize(getSize());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount and unmount

  return windowSize;
}

Compare.propTypes = propTypes;
Compare.defaultProps = defaultProps;

export default Compare;
