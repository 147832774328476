import React from "react";
import { Link } from "gatsby";
import classNames from "classnames";
import Image from "../../elements/Image";

const Logo = ({ className, ...props }) => {
  const classes = classNames("brand", className);

  return (
    <div
      {...props}
      className={classes}
      style={{ display: "flex", flexDirection: "row" }}
    >
      <div className="m-0" style={{ marginRight: ".5rem" }}>
        <svg
          width="32"
          height="32"
          viewBox="0 0 60 60"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect width="60" height="60" rx="6.4" fill="white" />
          <path
            d="M4.99902 47H54.999V50.8C54.999 53.451 52.85 55.6 50.199 55.6H9.79902C7.14806 55.6 4.99902 53.451 4.99902 50.8V47Z"
            fill="#454AFD"
          />
          <rect
            opacity="0.7"
            x="4.99902"
            y="38.6001"
            width="50"
            height="8.4"
            fill="#454AFD"
          />
          <rect
            opacity="0.4"
            x="4.99902"
            y="30"
            width="50"
            height="8.6"
            fill="#454AFD"
          />
        </svg>
      </div>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <p
          style={{
            margin: 0,
            color: "white",
            fontWeight: "bold",
            fontSize: "18px",
            lineHeight: "24px",
          }}
        >
          Above The Fold
        </p>

        <Link
          to="/"
          // href="https://www.visualeyes.design/?ref=above-the-fold-plugin"
          // target="_blank"
          // rel="noopener noreferrer"
          style={{
            display: "contents",
            marginTop: "0.25rem",
            textDecoration: "none",
            color: "white !important",
          }}
          className="remove-purple-links"
        >
          <small
            style={{
              margin: 0,
              fontSize: "12px",
              lineHeight: "100%",
              textAlign: "left",
              color: "white !important",
            }}
          >
            By VisualEyes.design
          </small>
        </Link>
      </div>
    </div>
  );
};

export default Logo;
