import React from "react";
import Button from "../elements/Button";
import { FigmaIcon } from "./Icons";

const DownloadButton = ({ style, onClick = () => {} }) => {
  return (
    <Button
      tag="div"
      onClick={onClick}
      color="primary"
      // wideMobile
      // href="https://www.visualeyes.design/"
      style={{
        background: "#E0F3FF",
        color: "#0573B5",
        fontWeight: "bold",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        ...style,
      }}
    >
      <FigmaIcon size={20} style={{ margin: "0 .5rem 0 0" }} />
      Download Plugin
    </Button>
  );
};

export default DownloadButton;
