import React from "react";
// import sections
import Hero from "../components/sections/Hero";
import HowItWorks from "../components/sections/HowItWorks";
import FeaturesTiles from "../components/sections/FeaturesTiles";
import FeaturesSplit from "../components/sections/FeaturesSplit";
import Testimonial from "../components/sections/Testimonial";
import Cta from "../components/sections/Cta";
import SEO from "../../components/SEO/SEO";
import favicon from "../assets/images/favicon.ico";
import AboveTheFoldTag from "../components/sections/partials/AboveTheFoldTag";
import Compare from "../components/sections/Compare";

const Home = () => {
  return (
    <>
      <SEO
        customOgImage="https://d34cuim5v38p5e.cloudfront.net/visualeyes/website/atf-og-image.jpg"
        customTitle="Above the Fold plugin for Figma & Sketch"
        description="Mark your designs with a layer that indicates where is the Above the Fold portion."
        customFavicon={favicon}
        pathname="/tools/above-the-fold"
        customTwitterOg="https://d34cuim5v38p5e.cloudfront.net/visualeyes/website/atf/atf-og-twitter-summary_large_image.jpg"
        tool={{ url: "/above-the-fold", title: "Above the Fold plugin" }}
      />

      <AboveTheFoldTag />
      <Hero className="illustration-section-01" />
      <HowItWorks />
      <Compare />
      {/* <FeaturesTiles /> */}
      <FeaturesSplit
        invertMobile
        topDivider
        imageFill
        className="illustration-section-02"
      />
      {/* <Testimonial topDivider /> */}
      <Cta split />
    </>
  );
};

export default Home;
