import React from "react";
import classNames from "classnames";
import { SectionTilesProps } from "../../utils/SectionProps";
import SectionHeader from "./partials/SectionHeader";
import Image from "../elements/Image";

const propTypes = {
  ...SectionTilesProps.types,
};

const defaultProps = {
  ...SectionTilesProps.defaults,
};
const HowItWorks = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {
  const outerClasses = classNames(
    "features-tiles section",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "features-tiles-inner section-inner pt-0",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  const tilesClasses = classNames(
    "tiles-wrap center-content",
    pushLeft && "push-left"
  );

  const sectionHeader = {
    title: "How It Works",
    paragraph:
      "We built Above the Fold plugin for the designers that want to check quickly which portion of their design will be visible in a browser window.",
  };

  return (
    <section {...props} className={outerClasses} id="how">
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className={tilesClasses}>
            <div className="tiles-item reveal-from-bottom">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <svg
                      width="64"
                      height="64"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g stroke-width="2" fill="none" fill-rule="evenodd">
                        <ellipse
                          stroke="#e9e9ff"
                          stroke-linecap="square"
                          cx="32"
                          cy="32"
                          rx="5.5"
                          ry="11"
                        />
                        <path d="M32 21v22M21 32h22" stroke="#FFF" />
                        <circle
                          stroke="#FFF"
                          stroke-linecap="square"
                          cx="32"
                          cy="32"
                          r="11"
                        />
                      </g>
                    </svg>
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h3 className="mt-0 mb-8">Gather Data</h3>
                  <p className="m-0 text-sm">
                    We gathered the most common viewport sizes around the web.
                    Viewport is the area where Browsers load the web pages and
                    >90%* is different to the user's screen size.{" "}
                  </p>
                  {/* https://css-tricks.com/screen-resolution-notequalto-browser-window/ */}
                  <div
                    style={{
                      color: "rgba(255, 255, 255, 0.4)",
                      fontSize: "12px",
                      lineHeight: "150%",
                      marginTop: "1rem",
                    }}
                  >
                    *Viewport and Screen size are the same only when the user is
                    on fullscreen mode!
                  </div>
                </div>
              </div>
            </div>

            <div
              className="tiles-item reveal-from-bottom"
              data-reveal-delay="200"
            >
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <svg
                      width="64"
                      height="64"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g
                        stroke-width="2"
                        fill="none"
                        fill-rule="evenodd"
                        stroke-linecap="square"
                      >
                        <path
                          d="M39 43v-6.3a8.97 8.97 0 003-6.569A9.1 9.1 0 0033.262 21 9 9 0 0024 30v1l-2 5 2 1v4a2 2 0 002 2h4a5 5 0 005-5v-5"
                          stroke="#FFF"
                        />
                        <circle stroke="#e9e9ff" cx="35" cy="30" r="3" />
                      </g>
                    </svg>
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h3 className="mt-0 mb-8">Aggregate dimensions</h3>
                  <p className="m-0 text-sm">
                    Then, we categorized the viewport sizes per device type
                    (desktop/mobile/tablet) and aggregated them to shape each
                    category. Also, we added the <b>danger zone</b>* which
                    pinpoints a possible unsafe area.
                    <div
                      style={{
                        color: "rgba(255, 255, 255, 0.4)",
                        fontSize: "12px",
                        lineHeight: "150%",
                        marginTop: "1rem",
                      }}
                    >
                      *Danger zone is an offset based on OS and browser's
                      navigation bars that vary per user.
                    </div>
                  </p>
                </div>
              </div>
            </div>

            <div
              className="tiles-item reveal-from-bottom"
              data-reveal-delay="400"
            >
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <svg
                      width="64"
                      height="64"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g
                        stroke-width="2"
                        fill="none"
                        fill-rule="evenodd"
                        stroke-linecap="square"
                      >
                        <path
                          d="M29 42h10.229a2 2 0 001.912-1.412l2.769-9A2 2 0 0042 29h-7v-4c0-2.373-1.251-3.494-2.764-3.86a1.006 1.006 0 00-1.236.979V26l-5 6"
                          stroke="#FFF"
                        />
                        <path stroke="#e9e9ff" d="M22 30h4v12h-4z" />
                      </g>
                    </svg>
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h3 className="mt-0 mb-8">Fit to your Artboard</h3>
                  <p className="m-0 text-sm">
                    Our algorithm analyzes your design by extracting artboards’
                    dimensions. Whilst providing you with the final results for
                    the selected target device, it eliminates scale
                    inconsistencies.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

HowItWorks.propTypes = propTypes;
HowItWorks.defaultProps = defaultProps;

export default HowItWorks;
