import React from "react";
import { Link } from "gatsby";
import classNames from "classnames";

const FooterNav = ({ className, ...props }) => {
  const classes = classNames("footer-nav", className);

  return (
    <nav {...props} className={classes}>
      <ul className="list-reset">
        <li>
          <Link to="/contact" className="remove-purple-links">
            {/* <a
            href="https://www.visualeyes.design/contact/?ref=above-the-fold-plugin"
            target="_blank"
            rel="noopener noreferrer"
            className="remove-purple-links"
          > */}
            Contact
            {/* </a> */}
          </Link>
        </li>
        <li>
          <Link to="/about" className="remove-purple-links">
            {/* <a
            href="https://www.visualeyes.design/about/?ref=above-the-fold-plugin"
            target="_blank"
            rel="noopener noreferrer"
            className="remove-purple-links"
          > */}
            About us
            {/* </a> */}
          </Link>
        </li>
      </ul>
    </nav>
  );
};

export default FooterNav;
