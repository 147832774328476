import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  z-index: 2;
  position: absolute;
  top: 100%;
  width: 100%;
  height: 4px;
`;

const IconWrapper = styled.div`
  cursor: pointer;
  z-index: 2;
  position: absolute;
  bottom: calc(100px + 1rem);
  right: 1rem;
  background: white;
  border-radius: 10000px;
`;

const Label = styled.div`
  z-index: 2;
  position: absolute;
  top: 100%;
  left: 32px;

  font-weight: 600;
  letter-spacing: 0px;
  color: white;
  font-size: 16px;
  transform: translateY(-100%);
  background: #454afc;
  padding: 0.25rem 1rem;
  border-radius: 4px 4px 0 0;
`;

const DashedLine = styled.hr`
  position: absolute;
  border: 0 none;
  border-top: 4px dashed #454afc;
  background: none;
  height: 0;
  width: 100%;
  ${"" /* transform: translateY(1px); */}
  margin: 0;
`;

const DangerZone = styled.div`
  position: absolute;
  width: 100%;
  min-height: 20px;
  max-height: 20px;
  background: rgba(255, 0, 0, ${(p) => 0.5 - 0.1 * p.level});
  top: calc(100% - ${(p) => 1 * p.level * 20}px);
  ${"" /* transform: translateY(${(p) => -1 * p.level * 100}%); */}
`;

const BelowTheFold = styled.div`
  position: absolute;
  width: 100%;
  height: 10000px;
  min-height: 10000px;
  max-height: 100000px;
  background: rgba(255, 255, 255, 0.5);
  top: 4px;
  z-index: 1;
`;

const DangerZoneLabel = styled.p`
  color: white;
  width: 100%;
  font-size: 12px;
  line-height: 24px;
  font-weight: bold;
  text-align: center;
  position: absolute;
  bottom: 0;
  margin: 0;
`;

const AboveTheFoldTag = () => {
  const [isOpen, setIsOpen] = React.useState(true);
  function toggle() {
    setIsOpen(!isOpen);
  }
  return (
    <Wrapper>
      {isOpen && (
        <>
          <DangerZone level={1} />
          <DangerZone level={2} />
          <DangerZone level={3} />
          <DangerZone level={4} />
          <DangerZoneLabel>DANGER ZONE</DangerZoneLabel>
          <DashedLine />
          <Label>1rst Screenful</Label>
          {/* <BelowTheFold /> */}
        </>
      )}

      {isOpen ? <CloseIcon onClick={toggle} /> : <OpenIcon onClick={toggle} />}
    </Wrapper>
  );
};

const ICON_SIZE = 40;
function OpenIcon({ onClick }) {
  return (
    <IconWrapper onClick={onClick}>
      <svg
        width={ICON_SIZE}
        height={ICON_SIZE}
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M10 20C15.5225 20 20 15.5229 20 10C20 4.47705 15.5225 0 10 0C4.47754 0 0 4.47705 0 10C0 15.5229 4.47754 20 10 20ZM13.6309 6.23047C13.2119 5.87646 12.5762 5.94531 12.1885 6.33398L8.9873 9.53809C8.89551 9.62988 8.89551 9.7793 8.9873 9.87158L10.124 11.0088C10.2158 11.1011 10.3652 11.1011 10.4561 11.0088L13.6992 7.76367C13.9014 7.56152 14.0039 7.29688 14 7.02832C14.0039 6.73145 13.8779 6.43896 13.6309 6.23047ZM7.5625 10.2739C6.66406 10.459 6.04297 11.2891 6.02734 12.207L6 13.7607C5.99805 13.8315 6.02832 13.8955 6.07812 13.9395C6.12109 13.978 6.17773 14.001 6.23926 14L7.8623 13.9707C8.59766 13.9575 9.29199 13.5439 9.58398 12.8691C10.2314 11.3711 9.02344 9.97266 7.5625 10.2739Z"
          fill="#454AFC"
        />
      </svg>
    </IconWrapper>
  );
}

function CloseIcon({ onClick }) {
  return (
    <IconWrapper onClick={onClick}>
      <svg
        width={ICON_SIZE}
        height={ICON_SIZE}
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M12.707 11.293C13.098 11.684 13.098 12.316 12.707 12.707C12.512 12.902 12.256 13 12 13C11.744 13 11.488 12.902 11.293 12.707L10 11.414L8.707 12.707C8.512 12.902 8.256 13 8 13C7.744 13 7.488 12.902 7.293 12.707C6.902 12.316 6.902 11.684 7.293 11.293L8.586 10L7.293 8.707C6.902 8.316 6.902 7.684 7.293 7.293C7.684 6.902 8.316 6.902 8.707 7.293L10 8.586L11.293 7.293C11.684 6.902 12.316 6.902 12.707 7.293C13.098 7.684 13.098 8.316 12.707 8.707L11.414 10L12.707 11.293ZM10 0C4.486 0 0 4.486 0 10C0 15.514 4.486 20 10 20C15.514 20 20 15.514 20 10C20 4.486 15.514 0 10 0V0Z"
          fill="#454AFC"
        />
      </svg>
    </IconWrapper>
  );
}

export default AboveTheFoldTag;
