import React, { useState, useEffect } from "react";
import axios from "axios";
import { SectionProps } from "../../../utils/SectionProps";
import Button from "../../elements/Button";
import Input from "../../elements/Input";
import Modal from "../../elements/Modal";

import { useCookies } from "react-cookie";

const DOWNLOAD_LINK =
  "https://github.com/loceye/sketch-above-the-fold/releases/latest/download/abovethefold.sketchplugin.zip";

const propTypes = {
  ...SectionProps.types,
};

const defaultProps = {
  ...SectionProps.defaults,
};

function validateEmail(email) {
  var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

const FormModal = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  formModalActive,
  closeModal,
  ...props
}) => {
  const [cookies, setCookie] = useCookies(["isSubscribedATF"]);

  useEffect(() => {
    if (cookies.isSubscribedATF === "yeah") {
      setReady(true);
    }
  }, []);

  const [email, setEmail] = useState("");
  const [ready, setReady] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const isEmailValid = validateEmail(email);

  function download() {
    const formData = new FormData();
    formData.append("email", email);
    axios
      .post("https://api.visualeyes.design/form", formData, {
        headers: { Authorization: "Token form-key" },
      })
      .then((response) => {
        console.log(response);
        setReady(true);
        setCookie("isSubscribedATF", "yeah");

        setHasError(false);
        window.open(DOWNLOAD_LINK, "_self");
      })
      .catch((error) => {
        setHasError(true);
        const status = error.response ? error.response.status : null;
        if (status === 403) {
          setErrorMessage(
            "You've already been subscribed! Check your inbox for the download link"
          );
          setReady(true);
          setCookie("isSubscribedATF", "yeah");
        } else {
          setErrorMessage(
            "Something went wrong! Contact info@loceye.io for help!"
          );
        }
        console.log(error);
      });
  }

  return (
    <Modal
      id="form-modal"
      show={formModalActive}
      handleClose={(e) => {
        closeModal(e);
      }}
    >
      {ready ? (
        <>
          <h1
            style={{
              fontSize: "24px",
              margin: 0,
              lineHeight: "140%",
              marginBottom: "1rem",
            }}
          >
            🎉 Tell your friends
          </h1>

          <p>
            Our team would be thrilled to learn what you think about our
            product!
          </p>

          <Button
            tag="button"
            color="light"
            wideMobile
            style={{
              width: "100%",
              minWidth: "100%",
              marginTop: "1rem",
              marginBottom: "1rem",
              color: "#fff",
              background: "#1DA1F2",
              fontWeight: "bold",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            onClick={() => {
              window.open(
                "https://twitter.com/intent/tweet?text=Check%20out%20Above%20the%20Fold%20plugin%20for%20Sketch%20by%20VisualEyes%0A%0Ahttps://www.visualeyes.design/tools/above-the-fold/?ref=twitter-share",
                "_blank"
              );
            }}
          >
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              xmlns="http://www.w3.org/2000/svg"
              style={{ marginLeft: 0, marginRight: "1rem" }}
            >
              <title>Twitter</title>
              <path
                fill="#fff"
                d="M16 3c-.6.3-1.2.4-1.9.5.7-.4 1.2-1 1.4-1.8-.6.4-1.3.6-2.1.8-.6-.6-1.5-1-2.4-1-1.7 0-3.2 1.5-3.2 3.3 0 .3 0 .5.1.7-2.7-.1-5.2-1.4-6.8-3.4-.3.5-.4 1-.4 1.7 0 1.1.6 2.1 1.5 2.7-.5 0-1-.2-1.5-.4C.7 7.7 1.8 9 3.3 9.3c-.3.1-.6.1-.9.1-.2 0-.4 0-.6-.1.4 1.3 1.6 2.3 3.1 2.3-1.1.9-2.5 1.4-4.1 1.4H0c1.5.9 3.2 1.5 5 1.5 6 0 9.3-5 9.3-9.3v-.4C15 4.3 15.6 3.7 16 3z"
              />
            </svg>
            <span>Share on Twitter</span>
          </Button>
          <a
            rel="noreferrer noopener"
            href={DOWNLOAD_LINK}
            style={{
              fontSize: "12px",
              color: "white",
            }}
          >
            If download didn't start automatically. Click here to download
            manually.
          </a>
        </>
      ) : (
        <>
          <h1
            style={{
              fontSize: "24px",
              margin: 0,
              lineHeight: "140%",
              marginBottom: "1rem",
            }}
          >
            Get Early Access
          </h1>
          <p>
            We'll inform you about exciting updates and upcoming features about
            our plugin. No spam!
          </p>
          <Input
            id="newsletter"
            type="email"
            label="Subscribe"
            labelHidden
            // hasIcon="right"
            placeholder="Your best email"
            onChange={(e) => setEmail(e.target.value)}
            value={email}
            style={{ borderRadius: "4px" }}
          />
          {hasError && (
            <p style={{ color: "red", fontSize: "12px" }}>{errorMessage}</p>
          )}
          <Button
            tag="button"
            color="light"
            wideMobile
            disabled={!isEmailValid}
            style={{
              width: "100%",
              minWidth: "100%",
              marginTop: "1rem",
              background: "rgb(254, 238, 183)",
              // color: "rgb(154, 91, 0)",
            }}
            onClick={download}
          >
            Download Plugin
          </Button>
        </>
      )}
    </Modal>
  );
};

FormModal.propTypes = propTypes;
FormModal.defaultProps = defaultProps;

export default FormModal;
