import React, { useState } from "react";
import classNames from "classnames";
import { SectionProps } from "../../utils/SectionProps";
import ButtonGroup from "../elements/ButtonGroup";
import Button from "../elements/Button";
import DownloadButton from "../custom/DownloadButton";
import DownloadFigma from "../custom/DownloadFigma";
import Image from "../elements/Image";
import Input from "../elements/Input";
import Modal from "../elements/Modal";
import { SketchIcon, FigmaIcon, AdobeXDIcon } from "../custom/Icons";
import FormModal from "./partials/FormModal";
import TextLoop from "react-text-loop";
import redirect from "../../../utils/redirect";

const propTypes = {
  ...SectionProps.types,
};

const defaultProps = {
  ...SectionProps.defaults,
};

const Hero = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
}) => {
  const [videoModalActive, setVideomodalactive] = useState(false);
  const [formModalActive, setFormmodalactive] = useState(false);

  const openModal = (e) => {
    e.preventDefault();
    setVideomodalactive(true);
  };

  const openFormModal = (e) => {
    e.preventDefault();
    setFormmodalactive(true);
  };

  const closeModal = (e) => {
    e.preventDefault();
    setVideomodalactive(false);
    setFormmodalactive(false);
  };

  const outerClasses = classNames(
    "hero section center-content",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "hero-inner section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  return (
    <section {...props} className={outerClasses}>
      <div className="container-sm">
        <div className={innerClasses}>
          <div className="hero-content">
            <h1
              className="mt-0 mb-16 reveal-from-bottom"
              data-reveal-delay="200"
            >
              Above the Fold plugin for{" "}
              <TextLoop>
                <span
                  className="text-color-primary"
                  style={{ color: "#FEEEB7" }}
                >
                  Sketch
                </span>
                <span
                  className="text-color-primary"
                  style={{ color: "#E0F3FF" }}
                >
                  Figma
                </span>
              </TextLoop>
            </h1>
            <div className="container-xs">
              <p
                className="m-0 mb-32 reveal-from-bottom"
                data-reveal-delay="400"
              >
                Make sure your most important elements will be visible every
                time on every device. Above the Fold plugin supports Desktop,
                Mobile and Tablet viewports.
              </p>
              <div className="reveal-from-bottom" data-reveal-delay="600">
                <ButtonGroup>
                  <DownloadButton onClick={openFormModal} />
                  <DownloadFigma
                    onClick={() => redirect("https://bit.ly/3duoVGA")}
                  />

                  {/* <Button
                    tag="a"
                    color="dark"
                    wideMobile
                    href="https://github.com/cruip/open-react-template/"
                  >
                    View on Github
                  </Button> */}
                </ButtonGroup>
              </div>
              {/* <p
                className="m-0 mb-32 reveal-from-bottom"
                data-reveal-delay="400"
                style={{ fontSize: "12px", marginTop: "1rem" }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  Soon available for{" "}
                  <FigmaIcon style={{ margin: "0.5rem" }} size={16} />
                  and
                  <AdobeXDIcon style={{ margin: "0.5rem" }} size={16} />
                </div>
                <a
                  href="https://draptis.typeform.com/to/RiaAZD"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    color: "rgba(255,255,255,0.3)",
                  }}
                >
                  Join the waiting list
                  <svg
                    width={16}
                    height={16}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    style={{ margin: 0, marginLeft: ".5rem" }}
                  >
                    <title>Open to new tab</title>
                    <g data-name="Layer 2">
                      <g data-name="external-link">
                        <rect width="24" height="24" opacity="0" />
                        <path
                          d="M20 11a1 1 0 0 0-1 1v6a1 1 0 0 1-1 1H6a1 1 0 0 1-1-1V6a1 1 0 0 1 1-1h6a1 1 0 0 0 0-2H6a3 3 0 0 0-3 3v12a3 3 0 0 0 3 3h12a3 3 0 0 0 3-3v-6a1 1 0 0 0-1-1z"
                          fill="rgba(255,255,255,0.2)"
                        />
                        <path
                          d="M16 5h1.58l-6.29 6.28a1 1 0 0 0 0 1.42 1 1 0 0 0 1.42 0L19 6.42V8a1 1 0 0 0 1 1 1 1 0 0 0 1-1V4a1 1 0 0 0-1-1h-4a1 1 0 0 0 0 2z"
                          fill="rgba(255,255,255,0.3)"
                        />
                      </g>
                    </g>
                  </svg>
                </a>
              </p> */}
            </div>
          </div>
          <div
            className="hero-figure  illustration-element-01"
            // data-reveal-distance="20px"
            // data-reveal-delay="1200"
          >
            <a
              // data-video="https://player.vimeo.com/video/174002812"
              data-video="https://www.youtube.com/embed/sLgSEIOgihA?rel=0"
              href="#0"
              aria-controls="video-modal"
              onClick={openModal}
            >
              <img
                className="has-shadow"
                src={
                  "https://d34cuim5v38p5e.cloudfront.net/visualeyes/website/atf/hero.jpg"
                }
                alt="Hero"
                width={896}
                height={504}
              />
            </a>
          </div>
          <Modal
            id="video-modal"
            show={videoModalActive}
            handleClose={closeModal}
            video="https://www.youtube.com/embed/Pf0wTKNada8"
            // video="https://player.vimeo.com/video/174002812"
            videoTag="iframe"
          />
          <FormModal
            formModalActive={formModalActive}
            closeModal={closeModal}
          />
        </div>
      </div>
    </section>
  );
};

Hero.propTypes = propTypes;
Hero.defaultProps = defaultProps;

export default Hero;
