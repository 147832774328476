import React from "react";

export const SketchIcon = ({ size = 24, color, style }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 40 40"
      fill="none"
      style={style}
    >
      <title>Sketch</title>
      <path
        d="M8.12756 14.3726L19.9583 39.954L0.0729675 14.3726H8.12756Z"
        fill="#EA6C00"
      ></path>
      <path
        d="M31.7924 14.3726L19.9617 39.954L39.847 14.3726H31.7924Z"
        fill="#EA6C00"
      ></path>
      <path
        d="M8.12714 14.3726H31.7886L19.9579 39.954L8.12714 14.3726Z"
        fill="#FDAD00"
      ></path>
      <path
        d="M19.9579 0.195312L8.74983 1.50415L8.12714 14.3725L19.9579 0.195312Z"
        fill="#FDD231"
      ></path>
      <path
        d="M19.9579 0.195312L31.1659 1.50415L31.7886 14.3725L19.9579 0.195312Z"
        fill="#FDD231"
      ></path>
      <path
        d="M39.847 14.3728L31.1698 1.50439L31.7925 14.3728H39.847Z"
        fill="#FDAD00"
      ></path>
      <path
        d="M0.0729675 14.3728L8.75026 1.50439L8.12756 14.3728H0.0729675Z"
        fill="#FDAD00"
      ></path>
      <path
        d="M19.9579 0.195312L8.12714 14.3725H31.7886L19.9579 0.195312Z"
        fill="#FEEEB7"
      ></path>
    </svg>
  );
};

export const FigmaIcon = ({ size, style, color }) => {
  return (
    <svg
      height={size}
      width={(size * 26) / 40}
      viewBox="0 0 26 40"
      fill="none"
      style={style}
    >
      <title>Figma</title>
      <path
        d="M6.42848 40.0003C9.97701 40.0003 12.857 37.0137 12.857 33.3337L12.857 26.667H6.42848C2.87996 26.667 0 29.6537 0 33.3337C0 37.0137 2.87996 40.0003 6.42848 40.0003Z"
        fill="#0ACF83"
      ></path>
      <path
        d="M0 20.0002C0 16.3202 2.87997 13.3335 6.4285 13.3335H12.857L12.857 26.6668H6.4285C2.87997 26.6668 0 23.6802 0 20.0002Z"
        fill="#A259FF"
      ></path>
      <path
        d="M0.000488281 6.66667C0.000488281 2.98667 2.88046 0 6.42899 0L12.8575 0L12.8575 13.3333L6.42899 13.3333C2.88046 13.3333 0.000488281 10.3467 0.000488281 6.66667Z"
        fill="#F24E1E"
      ></path>
      <path
        d="M12.8569 0H19.2854C22.8339 0 25.7139 2.98667 25.7139 6.66667C25.7139 10.3467 22.8339 13.3333 19.2854 13.3333H12.8569L12.8569 0Z"
        fill="#FF7262"
      ></path>
      <path
        d="M25.7142 20.0002C25.7142 23.6802 22.8342 26.6668 19.2857 26.6668C15.7371 26.6668 12.8572 23.6802 12.8572 20.0002C12.8572 16.3202 15.7371 13.3335 19.2857 13.3335C22.8342 13.3335 25.7142 16.3202 25.7142 20.0002Z"
        fill="#1ABCFE"
      ></path>
    </svg>
  );
};

export const AdobeXDIcon = ({ size, style, color }) => {
  return (
    <svg
      height={size}
      width={size}
      viewBox="0 0 40 40"
      fill="none"
      style={style}
    >
      <title>Adobe XD</title>
      <path
        d="M32.9168 40H7.0832C3.1664 40 0 36.7524 0 32.7352V7.26482C0 3.24759 3.1664 0 7.0832 0H32.9168C36.8336 0 40 3.24759 40 7.26482V32.7352C40 36.7524 36.8336 40 32.9168 40Z"
        fill="#FF26BE"
      ></path>
      <path
        d="M32.2832 38.2901H7.71684C4.38404 38.2901 1.66724 35.5036 1.66724 32.0853V7.91468C1.66724 4.49642 4.38404 1.70996 7.71684 1.70996H32.2672C35.6176 1.70996 38.3168 4.49642 38.3168 7.91468V32.0689C38.3328 35.5036 35.616 38.2901 32.2832 38.2901Z"
        fill="#2E001F"
      ></path>
      <path
        d="M15.816 18.7171L20.4832 27.9134C20.5664 28.0496 20.5168 28.1874 20.3824 28.1874H17.4816C17.2976 28.1874 17.2144 28.1366 17.1312 27.9659C16.064 25.7095 14.9808 23.4531 13.864 21.026H13.8304C12.8304 23.3169 11.7296 25.7275 10.664 27.9839C10.5808 28.1202 10.4976 28.1727 10.3632 28.1727H7.61601C7.44961 28.1727 7.43201 28.0365 7.51681 27.9331L12.0832 19.0092L7.66721 9.98354C7.56641 9.84733 7.66721 9.74395 7.76641 9.74395H10.6336C10.8 9.74395 10.8672 9.77841 10.9328 9.93267C11.9824 12.1891 13.0496 14.5144 14.0496 16.7872H14.0832C15.0496 14.5308 16.1168 12.1891 17.1504 9.94908C17.2336 9.81287 17.2832 9.72754 17.4512 9.72754H20.1344C20.2672 9.72754 20.3184 9.83092 20.2352 9.96713L15.816 18.7171ZM21.3008 21.5035C21.3008 17.5208 23.8848 14.4094 27.984 14.4094C28.3344 14.4094 28.5168 14.4094 28.8512 14.4438V9.91462C28.8512 9.81123 28.9344 9.74395 29.0176 9.74395H31.6512C31.784 9.74395 31.8176 9.79482 31.8176 9.88015V25.5733C31.8176 26.0344 31.8176 26.6153 31.9008 27.2488C31.9008 27.3522 31.8672 27.385 31.768 27.4375C30.368 28.1218 28.9008 28.4287 27.5008 28.4287C23.8832 28.4434 21.2992 26.1542 21.3008 21.5035ZM28.8496 17.1286C28.616 17.0252 28.2832 16.9579 27.8832 16.9579C25.7824 16.9579 24.3168 18.6153 24.3168 21.369C24.3168 24.5148 25.8176 25.78 27.7008 25.78C28.1008 25.78 28.5344 25.7292 28.8512 25.5913V17.1286H28.8496Z"
        fill="#FFD9F2"
      ></path>
    </svg>
  );
};
